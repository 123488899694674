import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Seo from '../components/seo'
import {lyricsHolder, lyricsLHS, lyricsRHS, linksHolder} from '../styles/PageStyles.module.scss'
import {motion} from 'framer-motion'
import PageTitle from '../components/PageTitle'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default function LyricsPage() {

 const released = Date.now() > 1627603201000
//  const released = Date.now() > 1627383576000
  

  return (
    <article className={lyricsHolder}>
      <Seo title="Lyrics" />
      <PageTitle>Lyrics</PageTitle>
      <section>
        <motion.div className={lyricsLHS} >
          <StaticImage
            src="../images/Lyrics Dot pic.png"
            alt="Dot Allison Lyrics"
            placeholder="blurred"
            layout="constrained"
            width={660}
            height={880}
          />
        </motion.div>
        <div className={lyricsRHS}>
          <ol className={linksHolder}>
            <li><AnchorLink to="/lyrics#long-exposure">Long Exposure</AnchorLink></li>
            { released && <><li><AnchorLink to="/lyrics#the-haunted">The Haunted</AnchorLink></li> 
            <li><AnchorLink to="/lyrics#constellations">Constellations</AnchorLink></li> </>}
            <li><AnchorLink to="/lyrics#can-you-hear-nature-sing">Can You Hear Nature Sing?</AnchorLink></li>
            {released && <><li><AnchorLink to="/lyrics#ghost-orchid">Ghost Orchid</AnchorLink></li>
            <li><AnchorLink to="/lyrics#forevers-not-much-time">Forever's Not Much Time</AnchorLink></li>
            <li><AnchorLink to="/lyrics#cue-the-tears">Cue The Tears</AnchorLink></li></> }
            <li><AnchorLink to="/lyrics#one-love">One Love</AnchorLink></li>
            { released && <><li><AnchorLink to="/lyrics#love-died-in-our-arms">Love Died In Our Arms</AnchorLink></li>
            <li><AnchorLink to="/lyrics#goodbye">Goodbye</AnchorLink></li></> }
          </ol>

          <div className="lyric">

          <h3 id="long-exposure" >Long Exposure</h3>

          <p>I didn&rsquo;t think you&rsquo;d lie to me<br />
          My love for you it was pure <br />
          Like glitter on snow<br />
          I cannot quite believe the deceit <br />
          What a fool love brings...<br />
          </p>

          <p>I held your memory high, encased in glass<br />
          Nothing could shatter or tarnish your sacred remains<br />
          But you came with your wrecking ball at last<br />
          What a fool I have been...<br />
          </p>

          <p>&lsquo;Cos I called through the trees<br />
          I called from the beach <br />
          I waited for you<br />
          Crying from all cliffs<br />
          and the photograph<br />
          That I held in my mind<br />
          Had a long exposure<br />
          Long exposure <br />
          To the truth<br />
          </p>

          <p>So I commit rare love to ink<br />
          That very night saw my letter dropped onto her fire<br />
          How could you turn so easily?<br />
          What a fool love brings...<br />
          </p>

          <p>For I called through the trees<br />
          I called from the beach <br />
          I waited for you<br />
          Crying from all cliffs<br />
          and the photograph<br />
          That I held in my mind<br />
          What a long exposure<br />
          Long exposure <br />
          To the truth<br />
          </p>

          <p>Orchards of cherries lie bruised on the ground <br />
          Bitten, discarded in grief all around<br />
          You settle for such easy fruit<br />
          What a fool that love brings&#8230;<br />
          </p>

          <p>For you&rsquo;ve called through the trees<br />
          You called from the beach<br />
          You&rsquo;ve been looking for me<br />
          Crying from all cliffs<br />
          and the photograph<br />
          That you held in your hand<br />
          Had a long exposure<br />
          But here I am, here I am, waiting<br />
          Here I am, here I am, waiting&#8230;<br />
          </p>

          <p>And that photograph of you<br />
          It&rsquo;s fading in the sunlight<br />
          and that photograph of you<br />
          It&rsquo;s fading in the sunlight&#8230;<br />
          </p>

          </div>
          
          {released && <>
          <div className="lyric">

          <h3 id="the-haunted" >The Haunted</h3>

          <p>Slip inside this haunted house<br />
          Tiptoe silent, not a sound<br />
          Oh, I feel ghosts<br />
          Ghosts all around me&#8230;<br />
          Sun cuts through this dusty hall<br />
          Spray-can dreams across my walls <br />
          With blood-red berries write in snow<br />
          Sign to the dead, who only know <br />
          </p>

          <p>Oh I feel ghosts<br />
          Feel their breath<br />
          Upon my neck <br />
          Oh I sense ghosts<br />
          Ghosts all around me<br />
          </p>

          <p>Light dust particles like stars<br />
          Seal my heart inside your jar<br />
          Darling cut me one more scar<br />
          Before we fall nowhere too far<br />
          Listen to what this corridor said<br />
          To love songs weeping from our bed<br />
          Feel the beat of skipping clocks<br />
          Hear the words this sweet hearth talks<br />
          </p>

          <p>Oh I feel ghosts<br />
          Feel their breath<br />
          Upon my neck <br />
          Oh I sense ghosts<br />
          Ghosts all around me<br />
          </p>

          <p>Let me lift that crown of thorns<br />
          All quitting was once sworn <br />
          Suture cuts, I&rsquo;ll dry your eyes <br />
          With words that hover like a sky...<br />
          Place your hand upon my mouth<br />
          Whisper &lsquo;Ssh&#8230;Do you trust me now?&rsquo;<br />
          You may burst through unbolted doors<br />
          Spray can your love across my walls <br />
          Haunt inside me forever more&#8230;<br />
          </p>

          <p>Oh feel my ghost<br />
          Feel my breath <br />
          Upon your nape<br />
          My ghost wrapped around you<br />
          Oh feel my walls<br />
          Oh be my ghost<br />
          Go on&#8230;be my ghost<br />
          Go on&#8230;be my ghost<br />
          </p>

          </div>
          
          <div className="lyric">
          <h3 id="constellations" >Constellations</h3>

          <p>You win, you always will<br />
          I lie still in the lake<br />
          Floating on my back<br />
          Gazing up at all the stars<br />
          A love, toothless like silk<br />
          I float free on the waves<br />
          Divine bed of nails<br />
          Gazing up at the all the stars <br />
          </p>

          <p>Break me,<br />
          Waving crashing over like stampedes of horses<br />
          That save me,<br />
          Cooling ice sculptures that quench me quite endlessly<br />
          Crave me,<br />
          Teardrops and rain that fall down on me silently<br />
          Bathe me...<br />
          </p>

          <p>Do you see the Constellations? <br />
          Do you see it now<br />
          Are you looking up?<br />
          Do you see the Constellations? <br />
          Do you see it now?<br />
          Are you looking now?<br />
          </p>

          <p>3 words, caught up in the sky <br />
          Seasons turn 10,000 times<br />
          Compressed love to diamonds <br />
          In our minds<br />
          3 words, caught up in our sky <br />
          Seasons turn 10,000 times<br />
          Compressed this love to diamonds <br />
          In our minds<br />
          </p>

          <p>Break me,<br />
          Waving crashing over like stampedes of horses<br />
          That save me,<br />
          Cool me with surf and foam quench me quite endlessly<br />
          Crave me,<br />
          Teardrops and rain that fall down on me silently<br />
          Bathe me...<br />
          </p>

          <p>Do you see the Constellations? <br />
          Do you see it now?<br />
          Are you looking up?<br />
          Do you see the Constellations? <br />
          Do you see it now?<br />
          Are you looking now?<br />
          </p>

          <p>An end, a single breath<br />
          I float still on the mirror-verse<br />
          Your eyes follow me back<br />
          Gazing up at all these stars&#8230;<br />
          </p>

          </div>
          </>
          }

          <div className="lyric">
          <h3 id="can-you-hear-nature-sing" >Can You Hear Nature Sing?</h3>

          <p>Autumn&rsquo;s touch, harvest worn <br />
          Winter&rsquo;s grasp beckons us on<br />
          Hallowed seed, occasional thorn<br />
          Unpick the wire around our dawn<br />
          </p>

          <p>Can you hear nature sing <br />
          A myriad of melodies?<br />
          Can you hear through her tears <br />
          A myriad of melodies?<br />
          Can you hear nature sing<br />
          Myriad of melodies?<br />
          Can you hear through her tears?<br />
          </p>

          <p>Acorns crack, upon the stone<br />
          Blossom, sepals, calyx unfold<br />
          Pregnant fruit, hanging low<br />
          Thaws my form into the snow<br />
          </p>

          <p>Can you hear nature sing<br />
          A myriad of melodies?<br />
          Can you hear through her tears? <br />
          A myriad of melodies?<br />
          Can you hear nature sing<br />
          A myriad of melodies?<br />
          Can you hear through her tears?<br />
          </p>

          <p>Mother smothering<br />
          With her favourite illusion<br />
          A forest of borrowed white<br />
          Evergreen devastation<br />
          Mother smothering<br />
          With her favourite illusion<br />
          There&rsquo;s a fire<br />
          Within our dawn<br />
          </p>

          <p>Can you hear nature sing<br />
          A myriad of melodies?<br />
          Can you hear through her tears? <br />
          A myriad of melodies?<br />
          Can you hear nature sing<br />
          A myriad of melodies?<br />
          Can you hear through her tears?<br />
          A myriad of melodies<br />
          Can you hear nature sing?<br />
          A myriad of melodies<br />
          Can you hear through her tears?<br />
          </p>

          </div>
          { released && <>
          <div className="lyric">

          <h3 id="ghost-orchid">Ghost Orchid</h3>

          <p>Sail to me, so gently<br />
          Ink your words all over me<br />
          I&rsquo;m evergreen like the pine<br />
          </p>

          <p>God knows I adored you<br />
          God knows I adored you <br />
          </p>

          <p>You&rsquo;re my church of snow<br />
          And everywhere was nowhere <br />
          Wherever we would go<br />
          I kneel down at my church of snow<br />
          I pray, I pray<br />
          </p>

          <p>Orchid ghost, all yours to spoil<br />
          My heaven-reaching petals born for your soil <br />
          Evergreen as I pined...<br />
          For one drop of your sweet nectar<br />
          To deepen the flower <br />
          Your love the lectern, where I could have learnt<br />
          </p>

          <p>God knows I adored you<br />
          God knows I adored you<br />
          </p>

          <p>You&rsquo;re my church of snow<br />
          And I&rsquo;ll be your disciple until we&rsquo;re old<br />
          Kneel down at my church of snow<br />
          You&rsquo;re everywhere yet nowhere wherever I go... <br />
          I kneel down at my church of snow<br />
          Melt into the sun<br />
          Kneel down with me, I&rsquo;ll be your church of snow<br />
          Kneel down beside me and<br />
          Melt into the sun<br />
          Melt into the sun</p>
          </div>

          <div className="lyric">
          <h3 id="forevers-not-much-time">Forever's Not Much Time</h3>

          <p>
            Lathe of night failed you, I&rsquo;m free<br />
            Hollow your shadow cast cold on me<br />
            Love&rsquo;s snow it sleet, I betroth <br />
            To love&rsquo;s splintered streets<br />
            Where the knitted-apartness of us sometimes meets&#8230;
          </p>

          <p>
            I miss you, like a dead man recalls life<br />
            We had forever, seems it&rsquo;s not much time
          </p>
          
          <p>
            Architect of war <br />
            (lay down your gun)<br />
            With love&rsquo;s holy oil<br />
            (lay down your pride)<br />
            You delight as I toil<br />
            (lay down your gun)<br />
            Crying, I watch your sweet memory spoil<br />
            (lay down your gun)
          </p>
          
          <p>
            And I miss you, like a dead man recalls life<br />
            We have forever, seems it&rsquo;s not... <br />
            Much time
          </p>
          
          <p>
            Tick, tock, tick, tock<br />
            Goes the beat <br />
            Of a heart.
          </p>
          </div>

          <div className="lyric">
          <h3 id="cue-the-tears">Cue The Tears</h3>
          
          <p>
            Love we were broken <br />
            Leaf skeleton frail<br />
            Just memory needles <br />
            To stitch up the sails<br />
            Love scars imbue me<br />
            Waves out of phase<br />
            Crackling Pylons<br />
            Silence no trace&#8230;
          </p>

          <p>
            The tree in our heart<br />
            Remained in dark <br />
            Where seeds cannot grow
          </p>
          
          <p>
            I shut out the pain<br />
            I thought I was made<br />
            Shut out the rain<br />
            As you cast me her shade<br />
            I didn&rsquo;t look back<br />
            I started to run<br />
            Perhaps we just<br />
            Did we shut out the sun?<br />
            Did we shut out the sun?<br />
            Prised apart by two guns<br />
            Did we shut out the sun?<br />
            Did we shut out the sun? (cue the tears)<br />
            Did we shut out the sun? (cue the tears)<br />
            Prized apart by two guns (cue the tears)<br />
            Did we shut out the sun? (cue the tears)
          </p>
          
          <p>
            We were so fallen<br />
            Leaf skeleton pale<br />
            With just these blunt needles <br />
            To stitch our torn sails<br />
            Love reigns down on us<br />
            Words that lost face<br />
            Deadening silence <br />
            Feigning as grace
          </p>
          
          <p>
            The tree in our heart<br />
            Remained in the dark<br />
            Where seeds cannot grow&#8230; 
          </p>
          
          <p>
            I shut out the pain<br />
            I thought I was made<br />
            I shut out the rain<br />
            As you cast me her shade<br />
            I didn&rsquo;t look back<br />
            I started to run<br />
            But with us perhaps we<br />
            Did we shut out the sun?<br />
            Did we shut out the sun?<br />
            Cue the tears<br />
            Cue the tears<br />
            Cue the tears<br />
            Cue the tears&#8230;.
          </p>
          </div>
          </>}

          <div className="lyric">
          <h3 id="one-love">One Love</h3>
          <p>
            I felt the breeze <br />
            Your robe of God<br />
            You left me broken<br />
            On my knees<br />
            My glass-blown dreams<br />
            Ground into sand <br />
            Tossed in my eyes<br />
            But now I see
          </p>
          
          <p>
            My flower<br />
            It had to lean toward the sunlight<br />
            To pollinate<br />
            Love don&rsquo;t bloom in the shade<br />
            My flower Fire Lily, Juliet Rose, Blood Camellia<br />
            My stem arcs toward the light <br />
            My petals close in the shade<br />
            No you need to shine, shine, shine here<br />
            Focus your photons of light<br />
            Shine on me <br />
            On one love
          </p>
          
          <p>
            How can the one <br />
            Etched in the stars<br />
            Be thrown aside<br />
            For spare parts?<br />
            Your shadow glowed<br />
            Along the road <br />
            You pulled the blackout curtains<br />
            On our shoots
          </p>
          
          <p>
            My flower<br />
            It had to lean towards the sunlight<br />
            Cross-pollinate <br />
            Love can&rsquo;t bloom in the shade<br />
            My flower, Fire Lily, Juliet Rose, Blood Carnellia <br />
            Stems arc toward the light<br />
            And my petals closed in her shade<br />
            No you need to <br />
            Shine, shine, shine here<br />
            Focus your photons of light<br />
            Shine on me<br />
            On one love
          </p>
          
          <p>
            Through the prism of a song<br />
            Words unconscious, like petals fall<br />
            Sepals are closing upon our day<br />
            In the shade I will fade away<br />
            No you need to shine... <br />
            Focus your photons of light <br />
            On one love
          </p>
          </div>
          { released && <>
          <div className="lyric">
          <h3 id="love-died-in-our-arms">Love Died In Our Arms</h3>
          
          <p>
            We&rsquo;ve got blood on our hands<br />
            We&rsquo;ve got blood on our hands<br />
            Love died in our arms<br />
            Love died in our arms<br />
            We got blood on our hands<br />
            We&rsquo;ve both got blood on our hands<br />
            This love died in our arms<br />
            This love died in our arms
          </p>
          
          <p>
            A little water, A little water<br />
            It&rsquo;s sure to clear us of this<br />
            A little water, A little water<br />
            It&rsquo;s sure to clear us of this
          </p>
          
          <p>
            So this was love<br />
            This was love<br />
            I can&rsquo;t feel the pulse now<br />
            So this was love<br />
            This was love<br />
            Ssh, I can&rsquo;t hear a sound now&#8230;<br />
            So this was love<br />
            This was love<br />
            Look but I can&rsquo;t see my breath now<br />
            So this was love, this was love<br />
            And it&rsquo;s gone, gone&#8230;
          </p>
          
          <p>
            Weaving in between these notes<br />
            Whispering to me<br />
            I can hear<br />
            Love&rsquo;s stubborn ghost&#8230;<br />
            Voices through the trees... 
          </p>
          
          <p>
            We&rsquo;ve got blood on our hands<br />
            We&rsquo;ve got blood on our hands<br />
            Love died in our hearts <br />
            Love died in our hearts <br />
            We got blood on our hands<br />
            We both got blood on our hands<br />
            This love it died in our arms <br />
            This love it died in our arms
          </p>
          
          <p>
            So this was love<br />
            This was love<br />
            I can&rsquo;t feel the pulse now<br />
            So this was love<br />
            This was love<br />
            Ssh, I can&rsquo;t hear a sound now&#8230;<br />
            This was love<br />
            This was love<br />
            Look but I can&rsquo;t see my breath now<br />
            So this was love, this was love<br />
            And it&rsquo;s gone, gone&#8230;
          </p>
          
          <p>
            &#8230;.And (whispered)<br />
            I am a man<br />
            I cry alone<br />
            I triumph and conquer<br />
            Here on my own<br />
            But what is this<br />
            That alters my path? (I can&rsquo;t feel the pulse now)<br />
            Shaking the tree<br />
            Of such a great man (I can&rsquo;t hear a sound now)<br />
            If being Godless<br />
            Used to set me free (I can&rsquo;t see my breath now)<br />
            What is this alter <br />
            I find here in front of me?
          </p>
          
          <p>
            So sweet is the taste <br />
            So firm the shape <br />
            This low hanging fruit <br />
            Such succulent flesh <br />
            On my skin to rest<br />
            This low hanging fruit<br />
            In orchards at night <br />
            I steal just one bite<br />
            This low hanging fruit<br />
            We&rsquo;ve got blood on our hands 
          </p>
          </div>
          
          <div className="lyric">
          <h3 id="goodbye">Goodbye</h3>
          
          
          <p>
            Return me my mind<br />
            &lsquo;Cos everything I see<br />
            Telling me I&rsquo;m blind<br />
            Idolatry statues seem to move<br />
            Whispering to me<br />
            &lsquo;I&rsquo;m yours&rsquo;
          </p>
          
          <p>
            Somewhere the heart<br />
            Of the pain<br />
            There&rsquo;s an answer <br />
            We&rsquo;ll find it one day<br />
            Until then, until then<br />
            I wish you love<br />
            I wish you love&#8230;<br />
            Goodbye<br />
          </p>
          
          <p>
            I see the sun <br />
            Diffracting through the rain<br />
            Seeds and flowers blossom<br />
            Through my veins <br />
            Though I am the one<br />
            Sails upon your gaze<br />
            Everything I ever seem to do<br />
            ...In vain
          </p>
          
          <p>
            Somewhere in the heart<br />
            Of the pain<br />
            There&rsquo;s an answer <br />
            We&rsquo;ll find it one day<br />
            Until then, Until then<br />
            I wish you love<br />
            I wish you love... <br />
            Somewhere in the heart<br />
            Of the pain<br />
            There&rsquo;ll be an answer <br />
            May you find it one day<br />
            Until then, until then<br />
            I wish you love<br />
            I wish you love... <br />
            Goodbye
          </p>
          </div>
          </>
      }
        </div>
      </section>
    </article>
  )
}
